<template>
  <DialogDescription
    as="div"
    class="flex flex-col items-center gap-4"
  >
    <div class="flex-center">
      <div
        ref="animationContainer"
        class="w-24 h-24"
      />
    </div>
    <h1>Stay Alert</h1>
    <p class="text-center">
      Turn on push notifications because...<br>
      you love oatmeal.
    </p>
    <div class="w-full grid grid-cols-2 gap-2">
      <button
        class="button"
        gata-gtm-category="notification prompt"
        data-gtm-action="deny"
        @click="$app.modal.close()"
      >
        Maybe Later
      </button>
      <button
        class="button +green"
        gata-gtm-category="notification prompt"
        data-gtm-action="accept"
        @click="$emit('accept')"
      >
        Turn On
      </button>
    </div>
  </DialogDescription>
</template>

<script setup lang="ts">
import { addDays } from 'date-fns'
import lottie from 'lottie-web/build/player/lottie_light'
import { DialogDescription } from '@headlessui/vue'
import { get, set } from '@/utils/storage'
import animationData from '@/assets/lottie/notifications.json'

defineEmits<{
  (e: 'accept'): void
}>()

const animationContainer = ref()
onMounted(() => {
  // if they've already dismissed once, wait an additional 14 days before showing again
  const date = get('notification-prompt') ? addDays(new Date(), 14) : new Date()
  set('notification-prompt', date)

  lottie.loadAnimation({
    container: animationContainer.value,
    loop: false,
    autoplay: true,
    animationData
  })
})
</script>
